<template>
  <div class="LoginForm">
    <div class="loginpassword" v-if="isloginpassword">
      <div class="LoginFormTitle">
        <span
          :class="isaccountlogin ? 'curr' : ''"
          @click="onshowaccountlogin(1)"
          >密码登录</span
        >
        <span
          :class="!isaccountlogin ? 'curr' : ''"
          @click="onshowaccountlogin(2)"
          >短信登录</span
        >
      </div>
      <div>
        <form
          v-if="isaccountlogin"
          action
          enctype="application/x-www-form-urlencoded"
          class="LoginFormBox"
          @keyup.enter="LoginTo"
        >
          <el-input
            type="text"
            autoComplete="off"
            placeholder="请输入手机号码"
            class="Formtext"
            v-model="formMess.useraccount"
          >
            <template slot="prepend"
              ><i class="icon el-icon-user"></i
            ></template>
          </el-input>
          <el-input
            type="password"
            autoComplete="off"
            placeholder="密码"
            class="Formtext"
            v-model="formMess.userpassword"
          >
            <template slot="prepend"
              ><i class="icon el-icon-lock"></i
            ></template>
          </el-input>
          <el-button class="loginBtn" type="primary" @click="LoginTo">
            登录
          </el-button>
        </form>
        <form
          v-if="!isaccountlogin"
          action
          enctype="application/x-www-form-urlencoded"
          class="LoginFormBox"
          @keyup.enter="LoginToBySms"
        >
          <el-input
            type="text"
            autoComplete="off"
            placeholder="请输入手机号码"
            class="Formtext"
            v-model="formMess.useraccount"
          >
            <template slot="prepend"
              ><i class="icon el-icon-user"></i
            ></template>
          </el-input>
          <el-input
            type="text"
            autoComplete="off"
            placeholder="验证码"
            class="Formtext sendcode"
            v-model="formMess.verifyCode"
          >
            <template slot="prepend"
              ><i class="icon el-icon-mouse"></i
            ></template>
            <el-button class="" slot="append" @click="getCode('formMess')">{{
              codeMsg
            }}</el-button>
          </el-input>
          <el-button class="loginBtn" type="primary" @click="LoginToBySms">
            登录
          </el-button>
        </form>
      </div>
      <div class="loginExplain">
        <router-link to="/user/register">
          注册账号
        </router-link>
        <router-link style="margin-left:15px" to="/user/findpwd">
          忘记密码
        </router-link>
      </div>
    </div>
    <div class="loginqrcode" v-if="!isloginpassword">
      <div class="LoginFormTitle"><span>扫码登录</span></div>
      <div>
        <div class="appLoginWait" style="display: block;">
          <div class="appLoginWaitInner">
            <div class="appCodeWrap">
              <div class="appCodeBox">
                <div class="appCode">
                  <img
                    class="appCodeImg"
                    v-if="isshowqrcode"
                    :src="qrcodesrc"
                    width="100%"
                    height="100%"
                  />
                  <img
                    class="appCodeLoading"
                    v-if="!isshowqrcode"
                    width="16"
                    height="16"
                    src="https://mimg.127.net/p/freemail/index/lib/img/loading_s.gif"
                    data-src="https://mimg.127.net/p/freemail/index/lib/img/loading_s.gif"
                  />
                </div>

                <div class="appCodeRefresh" v-if="isappCodeRefresh">
                  <div class="appCode-mask"></div>
                  <div class="appCode-wrap">
                    <p class="appCodeMsg" v-if="isappCodeMsg">
                      {{ appCodeMsg }}
                    </p>
                    <a
                      class="appCodeRefreshButton"
                      v-if="isappCodeRefreshButton"
                      @click="onappCodeRefreshButton"
                      >刷新二维码</a
                    >
                    <p class="appCodeMsgo" v-if="isappCodeMsgo">
                      {{ appCodeMsgo }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="u-scan-tips">
                打开
                <a href="JavaScript:;" rel="noopener norefferrer"> 牛豆豆APP </a
                >扫一扫登录
              </div>
              <div class="loginExplain">
                <router-link to="/user/register">
                  注册账号
                </router-link>
                <router-link style="margin-left:15px" to="/user/findpwd">
                  忘记密码
                </router-link>
              </div>
            </div>
          </div>
          <p class="appLoginTxt txt-err" style="display: none;"></p>
        </div>
      </div>
      <div class="loginExplain" style="display:none">
        <router-link to="/user/register">
          注册账号
        </router-link>
        <router-link style="margin-left:15px" to="/user/findpwd">
          忘记密码
        </router-link>
      </div>
    </div>
    <div class="corner-icon-view view-type-qrcode" v-if="isloginpassword">
      <i class="iconfont icon-qrcode" @click="onchangeloginpopup(false)"> </i>
      <div class="login-tip" v-if="false">
        <div class="poptip">
          <div class="poptip-arrow"><em></em><span></span></div>
          <div class="poptip-content">扫码安全登录</div>
        </div>
      </div>
    </div>
    <div class="corner-icon-view view-type-password" v-if="!isloginpassword">
      <i class="iconfont icon-password" @click="onchangeloginpopup(true)"> </i>
      <div class="login-tip" v-if="false">
        <div class="poptip">
          <div class="poptip-arrow"><em></em><span></span></div>
          <div class="poptip-content">密码安全登录</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {
  userlogin,
  extendlogingetloginqrcode,
  userlogout,
  usergetcurruserinfo,
  usersenduserloginsms,
  userloginbysms
} from '@/api/user';
import { extendloginqueryqrcodelogin } from '@/api/extendlogin';
import {
  getCookieByName,
  setCookieByName,
  removeCookieByName
} from '@/utils/helper';
export default {
  data() {
    return {
      isshowdownqrapp: false,
      codeMsg: '获取验证码',
      second: 60,
      timer: null,
      isaccountlogin: true,
      isloginpassword: false,
      dialogVisible: true,
      citys: [],
      //登录表单
      formMess: {
        useraccount: '',
        userpassword: '',
        verifyCode: ''
      },
      // 用户id
      userAccount: '',
      // 用户的显示与隐藏
      UserBoolean: true,
      activeName: 'first',
      isshowqrcode: false,
      qrcodesrc: '',
      appCodeMsg: '',
      appCodeMsgo: '',
      isappCodeMsg: false,
      isappCodeMsgo: false,
      isappCodeRefresh: false,
      isappCodeRefreshButton: false
    };
  },
  created() {
    const self = this;
    let accessToken = getCookieByName('access_token');
    if (accessToken) {
      this.userAccount = getCookieByName('user_account');
      this.UserBoolean = false;
    }
    this.onextendlogingetloginqrcode();
  },
  methods: {
    onqrmouseover() {
      this.isshowdownqrapp = true;
    },
    onqrmouseleave() {
      this.isshowdownqrapp = false;
    },
    changeInput() {
      var phone = /^[1]([3-9])[0-9]{9}$/;
      if (phone.test(this.formMess.useraccount)) {
        this.$message.error('请输入正确的手机号');
      }
    },
    getCode(formName) {
      this.dbget = true;
      if (this.formMess.useraccount == '') {
        this.$message.error('请输入手机号');
        return;
      }
      var phone = /^[1]([3-9])[0-9]{9}$/;
      if (!phone.test(this.formMess.useraccount)) {
        this.$message.error('请输入正确的手机号');
        return;
      }
      if (this.second != 60) {
        this.$message.error(`请${this.second}秒后重试`);
        return;
      }
      usersenduserloginsms({ userMobile: this.formMess.useraccount }).then(
        res => {
          if (res.data.code === 1) {
            this.$message({
              message: '发送成功',
              type: 'success'
            });
            this.codeMsg = `${this.second}s后重试`;
            this.timer = setInterval(() => {
              this.second--;
              this.codeMsg = `${this.second}s后重试`;
              if (this.second == 0) {
                clearInterval(this.timer);
                this.second = 60;
                this.codeMsg = '获取验证码';
              }
            }, 1000);
          } else {
            this.$message.error(res.data.message);
            //this.phone = false;
          }
        }
      );
      return;
    },
    onshowaccountlogin(v) {
      if (v == 1) {
        this.isaccountlogin = true;
      } else {
        this.formMess.useraccount = '';
        this.isaccountlogin = false;
        this.formMess.verifyCode = '';
      }
    },
    keyupSubmit() {
      document.onkeydown = e => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          this.LoginTo();
        }
      };
    },
    oncloselogindialog() {
      if (this.iTime) {
        clearInterval(this.iTime);
      }
      this.$emit('closeShow', false);
    },
    onappCodeRefreshButton() {
      if (this.iTime) {
        clearInterval(this.iTime);
      }
      this.onextendlogingetloginqrcode();
    },
    onchangeloginpopup(flag) {
      this.isloginpassword = flag;
      if (this.iTime) {
        clearInterval(this.iTime);
      }
      if (!flag) {
        this.onextendlogingetloginqrcode();
      }
      this.formMess.useraccount = '';
      this.formMess.verifyCode = '';
      this.formMess.userpassword = '';
    },
    gotomine() {
      if (this.userAccount != '') {
        let routeData = this.$router.resolve({
          path: '/order/list',
          query: {}
        });
        window.open(routeData.href, '_blank');
      } else {
        this.dialogVisible = true;
        this.onextendlogingetloginqrcode();
      }
    },
    gotoregister() {
      let routeData = this.$router.resolve({
        path: '/user/register',
        query: {}
      });
      window.open(routeData.href, '_blank');
    },
    goToLogin() {
      this.dialogVisible = true;
      this.onextendlogingetloginqrcode();
    },
    LoginTo() {
      let formData = new FormData();
      formData.append('useraccount', this.formMess.useraccount);
      formData.append('userpassword', this.formMess.userpassword);

      let baseUrl = process.env.VUE_APP_URL3;
      var that = this;
      axios.post(`${baseUrl}/user/userlogin`, formData).then(res => {
        if (res.data.code == 1) {
          setCookieByName('access_token', res.data.result.access_token);
          setCookieByName('user_account', res.data.result.user_account);
          setCookieByName('user_id', res.data.result.user_id);

          this.userAccount = res.data.result.user_account;
          this.UserBoolean = false;
          this.dialogVisible = false;
          location.reload();
        } else {
          this.$message.error(res.data.message);
          this.removeCookies();
          this.UserBoolean = true;
        }
      });
    },
    LoginToBySms() {
      let formData = new FormData();
      formData.append('userMobile', this.formMess.useraccount);
      formData.append('verifyCode', this.formMess.verifyCode);

      let baseUrl = process.env.VUE_APP_URL3;
      var that = this;
      axios.post(`${baseUrl}/user/userloginbysms`, formData).then(res => {
        if (res.data.code == 1) {
          setCookieByName('access_token', res.data.result.access_token);
          setCookieByName('user_account', res.data.result.user_account);
          setCookieByName('user_id', res.data.result.user_id);

          this.userAccount = res.data.result.user_account;
          this.UserBoolean = false;
          this.dialogVisible = false;
          location.reload();
        } else {
          this.$message.error(res.data.message);
          this.removeCookies();
          this.UserBoolean = true;
        }
      });
    },
    signOut() {
      this.UserBoolean = true;

      userlogout().then(res => {
        // if (res.data.code === 1) {
        // }
        this.removeCookies();
        location.reload();
      });
    },
    removeCookies() {
      removeCookieByName('access_token');
      removeCookieByName('user_account');
      removeCookieByName('refresh_token');
      removeCookieByName('user_id');
      removeCookieByName('iscompanyauthsuccess');
    },
    // 关闭登录对话框
    closeDialog() {
      this.dialogVisible = false;
    },
    onextendlogingetloginqrcode() {
      this.isappCodeRefresh = false;
      let baseUrl = process.env.VUE_APP_URL3;
      var that = this;
      axios
        .get(`${baseUrl}/extendlogin/getloginqrcode`, {
          params: { requestid: this.requestid, t: Math.random() }
        })
        .then(res => {
          if (res.data.code == 1) {
            this.qrcodesrc =
              'data:image/png;base64,' + res.data.result.qrcodeBase64;
            this.isshowqrcode = true;
            this.requestid = res.data.result.requestid;
            this.onextendloginqueryqrcodelogin();
          }
        });
    },
    onextendloginqueryqrcodelogin() {
      axios.defaults.withCredentials = true;
      axios.defaults.crossDomain = true;
      axios.defaults.headers.post['Content-Type'] =
        'application/x-www-form-urlencoded';

      let baseUrl = '';
      if (process.env.VUE_APP_ENV === 'development') {
        baseUrl = process.env.VUE_APP_URL3;
      } else if (process.env.NODE_ENV === 'production') {
        baseUrl = process.env.VUE_APP_URL3;
      } else {
      }
      var that = this;
      axios
        .get(`${baseUrl}/extendlogin/queryqrcodelogin`, {
          params: { requestid: this.requestid, t: Math.random() }
        })
        .then(res => {
          if (res.data.code == 1) {
            if (res.data.result.status == -2) {
              that.isappCodeRefresh = true;
              that.isappCodeRefreshButton = true;
              that.appCodeMsg = '二维码已失效';
              that.isappCodeMsg = true;
              that.isappCodeMsgo = false;
              clearInterval(this.iTime);
            } else if (res.data.result.status == -1) {
              that.isappCodeRefresh = true;
              that.isappCodeRefreshButton = true;
              that.appCodeMsgo = '登录已取消';
              that.isappCodeMsg = false;
              that.isappCodeMsgo = true;
              clearInterval(this.iTime);
            } else if (res.data.result.status == 1) {
              that.isappCodeRefresh = true;
              that.isappCodeRefreshButton = false;
              that.appCodeMsgo = '已扫码';
              that.isappCodeMsgo = true;
              that.isappCodeMsg = false;
              this.iTime = setTimeout(() => {
                that.onextendloginqueryqrcodelogin();
              }, 1000);
            } else if (res.data.result.status == 2) {
              clearInterval(this.iTime);
              setCookieByName('access_token', res.data.result.access_token);
              setCookieByName('user_account', res.data.result.user_account);
              setCookieByName('user_id', res.data.result.user_id);
              this.userAccount = res.data.result.user_account;
              this.UserBoolean = false;
              window.location.reload();
            } else {
              that.iTime = setTimeout(() => {
                that.onextendloginqueryqrcodelogin();
              }, 1000);
            }
          } else {
            that.isappCodeRefresh = true;
            that.isappCodeRefreshButton = true;
            that.appCodeMsg = '二维码已失效';
            that.isappCodeMsg = true;
            that.isappCodeMsgo = false;
            clearInterval(this.iTime);
          }
        });
    }
  }
};
</script>
<style scoped>
.LoginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 420px;
  background-color: #fff;
  border-radius: 15px;
}
.LoginFormTitle {
  font-size: 16px;
  color: #333;
  text-align: left;
  padding-left: 20px;
  margin: 10px 0 0px 0;
  font-weight: 600;
}
.LoginFormTitle span {
  margin-right: 20px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
}
.LoginFormTitle span::after {
  content: '';
  display: inline-block;
  background: #fff;
  height: 3px;
  width: 1%;
}
.loginqrcode .LoginFormTitle span::after {
  content: '';
  display: inline-block;
  background: #fff;
  height: 3px;
  width: 1%;
  display: none;
}
.LoginFormTitle span.curr::after {
  content: '';
  display: inline-block;
  background: #333;
  width: 100%;
  height: 3px;
  position: relative;
  top: -5px;
  transition: all 0.2s;
}
.LoginForm .el-form-item .el-form-item__content {
  margin-left: 0;
}
.LoginForm .formName {
  width: 355px;
  height: 50px;
  margin-left: -70px;
}
.LoginForm .formNames {
  width: 355px;
  height: 50px;
  margin-left: -70px;
}
.LoginForm .LoginformItem1 {
  margin-bottom: 4px;
}
.LoginForm .loginBtn {
  width: 60%;
  margin-top: 7px;
  margin-bottom: 5px;
  padding: 8px 20px;
}
.LoginForm .loginExplain {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  /* margin-bottom: 35px; */
  font-size: 12px;
  color: #f6671a;
}
.LoginForm .loginExplain:hover {
  color: #23527c;
  cursor: pointer;
}
.LoginForm .LoginFormBox {
  display: block;
  flex-direction: column;
  text-align: center;
  margin-top: 0;
}
.LoginForm .Formtext {
  width: 180px;
  /* height: 50px; */
  border: 0;
  outline: none;
  /* margin-left: 10px; */
  background-color: rgba(0, 0, 0, 0);
  /* border-bottom: 1px solid #ccc; */
  font-size: 14px;
  margin-top: 10px;
}
.Formtext >>> .el-input-group__prepend {
  width: 10px;
  padding: 0 10px;
}
.Formtext >>> .el-input__inner {
  padding: 0 7px;
}

.Formtext.sendcode >>> .el-input-group__append {
  cursor: pointer;
}
.Formtext >>> .el-button {
  padding: 5px 20px;
}
.Formtext.sendcode >>> .el-button {
  padding: 9px 5px;
  font-size: 12px;
}
.corner-icon-view .iconfont {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 52px;
  line-height: 52px;
  cursor: pointer;
  color: #f40;
}
.corner-icon-view .iconfont.icon-qrcode {
  background-image: url('../assets/img/login-qcode.png');
  background-size: 100% 100%;
  width: 30px;
  height: 30px;
}
.corner-icon-view .iconfont.icon-password {
  background-image: url('../assets/img/login-password.png');
  background-size: 100% 100%;
  width: 30px;
  height: 30px;
}

.corner-icon-view .login-tip {
  position: absolute;
  top: 0;
  right: 45px;
  display: block;
}
.corner-icon-view .login-tip {
  top: 9px;
  right: 32px;
  width: 80px;
}
.corner-icon-view .poptip {
  line-height: 16px;
  position: relative;
  z-index: 9999;
  background-color: #fefcee;
  border: 1px solid #ff9000;
  padding: 3px;
}
.corner-icon-view .login-tip .poptip {
  border: 1px solid #f3d995;
  background: #fefcee;
}
.corner-icon-view .poptip .poptip-arrow em,
.corner-icon-view .poptip .poptip-arrow span {
  position: absolute;
  *zoom: 1;
  width: 0;
  height: 0;
  border-color: hsla(0, 0%, 100%, 0);
  border-color: transparent \0;
  border-style: solid;
  overflow: hidden;
  top: 0;
  left: 0;
  border-left-color: #ff9000;
}
.LoginForm .loginExplain a {
  color: #23527c;
  text-decoration: unset;
}
.corner-icon-view .poptip .poptip-arrow {
  position: absolute;
  z-index: 10;
  *zoom: 1;
  top: 8px;
  right: 0;
}
.corner-icon-view .poptip .poptip-arrow em {
  top: 0;
  left: 1px;
  border-left-color: #ff9000;
  border-width: 6px 0 6px 6px;
}
.corner-icon-view .poptip .poptip-arrow span {
  border-left-color: #fefcee;
  border-width: 6px 0 6px 6px;
}

.corner-icon-view .poptip .poptip-content {
  color: #ff9000;
  font-size: 12px;
  font-weight: 400;
}

.appLoginTab {
  font-size: 16px;
  color: #626262;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.appCodeWrap {
  margin-top: 10px;
  height: 128px;
  position: relative;
  text-align: center;
}
.appCodeBox,
.appCode-example {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.appCode {
  margin: 0 auto;
  background: #fff;
  border: 0;
  box-shadow: 0 0 6px #eee;
  padding: 3px;
  width: 120px;
  height: 120px;
}
.appCodeLoading {
  /* position: relative; */
  top: 86px;
  left: 175px;
  border: 0;
  margin-top: 40px;
}
.appCodeRefresh {
  position: absolute;
  top: -10px;
  left: 5px;
  width: 190px;
  height: 183px;
  cursor: pointer;
}
.appCode-mask {
  height: 100%;
  background: #fff;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.appCode-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 60px;
  text-align: center;
  color: #333;
  font-size: 14px;
  line-height: 1;
}
.appCode-wrap a {
  display: block;
  width: 100px;
  height: 36px;
  background-color: #ff6600;
  color: #fff;
  line-height: 36px;
  margin: 12px auto 0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.appLoginWait {
  border-bottom: none;
  margin: 0px 0;
}
.appLoginWait .u-scan-tips {
  margin-top: 8px;
}
.appLoginWait .u-scan-tips a {
  color: #ff9900;
}
.appCodeMsgo {
  margin-top: 18%;
  font-size: 14px;
}
.Formtext >>> .el-input-group > .el-input__inner {
  height: 35px;
  line-height: 35px;
}
</style>
